.calendar-box{
  margin: 10px 0;
  border-bottom: 1px solid #00794c;

  .content-box-title{
    background-color: #00794c;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 10px;

    a,
    h3 {
      @include font-folio;
      text-transform: uppercase;
      display: block;
      color: #FFF;
      margin: 0;
      padding: 0;
    }

    h3{
      font-size: 1.6em;
    }

    a{
      text-decoration: underline;
      text-align: right;
    }

    &:before, &:after{
      display:none;
    }
  }

  .content-box-content{
    display:flex;
    flex-direction:column;
    justify-content: flex-start;

    .event-holder{
      display:block;
      padding: 10px;
      background:#f1f1f1;
      border-bottom: 1px solid #e6e6e6;
      h4{
        font-size: 18px;
        color:#333;
        margin-bottom:0;
      }
    }

    a.event-holder:hover{
      text-decoration:none;
      color: #00794c;
      background:#e6e6e6;
    }
  }
}
