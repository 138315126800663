.partner-box {
  display: block;
  margin-bottom: $grid-gutter-width / 2;

  &:hover {
    opacity: .8;
    text-decoration: none;
    color: #fff;
  }

  .partner-link {
    &:hover {
      text-decoration: none;
    }

    .partner-logo-bg {
      border: 1px solid #ddd;
      background: #fff;
      /* Old browsers */
      background: -moz-radial-gradient(center, ellipse cover, #fff 45%, #ddd 100%);
      /* FF3.6+ */
      background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(45%, #fff), color-stop(100%, #ddd));
      /* Chrome,Safari4+ */
      background: -webkit-radial-gradient(center, ellipse cover, #fff 45%, #ddd 100%);
      /* Chrome10+,Safari5.1+ */
      background: -o-radial-gradient(center, ellipse cover, #fff 45%, #ddd 100%);
      /* Opera 12+ */
      background: -ms-radial-gradient(center, ellipse cover, #fff 45%, #ddd 100%);
      /* IE10+ */
      background: radial-gradient(ellipse at center, #ffffff 45%, #dddddd 100%);
      /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#007946', endColorstr='#004324', GradientType=1);
      /* IE6-9 fallback on horizontal gradient */
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 90px;
    }

    .partner-title {
      margin-top: -1px;
      color: white;
      background-color: $brand-primary;
      font-weight: 500;
    }
  }
}

.modal-logo {
  max-width: 200px;
}
