#member_form{
  .form-add-holder {
    display: none;
    background: #f9f9f9;
    //border-top: 1px solid #eee;
    padding: 10px;
    margin: 0 -10px;

    hr {
      border-color: #ddd;
      margin: 10px 0;
    }
  }

  .form-text {
    font-style: italic;
    background: #ddd;
  }

  .content-box {

    .content-box-title {

      h3 {
        font-family: 'Roboto', Helvetica, Arial, sans-serif;
        font-size: 17px;
      }
    }
  }
}
