.d-flex{
  display:flex;
}
.flex-direction-column{
  flex-direction: column;
}
.justify-content-end{
  justify-content: flex-end;
}
.justify-content-between{
  justify-content: space-between;
}

.justify-content-center{
  justify-content: center;
}
.flex-wrap{
  flex-wrap: wrap;
}
.align-items-center{
  align-items: center;
}
.align-items-stretch{
  align-items: stretch;
}
.font-bold{
  font-weight:bold;
}

.font-chalk{
  // font-family: 'East Sea Dokdo', cursive;
  font-family: 'Permanent Marker', cursive;
}

.text-disabled{
  opacity:0.5;
}

.schoolday{
  margin-top:0;
  margin-bottom:50px;
  min-height:600px;

  .spacer{
    display:none;
  }

  .schoolday-sponsor-logo {
    max-height: 50px;
  }

  .schoolday-top {
    background: #00794c;
    box-shadow: 0 5px 30px 5px rgba(0,0,0,.3);
    padding:20px 0;
    z-index:50;
    position:relative;

    .language-select  {
      list-style: none;
      display: inline-block;
      padding: 0;
      font-size: 0.9em;
      font-weight: bold;
      margin-left: 10px;

      li {
        display: inline-block;

        a {
          color: #fff;
          text-transform: uppercase;

          img {
            width: 24px;
            height: auto;
          }
        }
      }
    }
  }

  .schoolday-container{
    position:relative;
    z-index:40;
    box-shadow: 0 5px 30px 5px rgba(0,0,0,.3);
  }

  .schoolday-inner{

    margin-left: -6px;
    margin-right: -6px;

    .schoolday-header{
      position:relative;
      height: 500px;
      background-repeat:no-repeat;
      background-size:cover;

      &:before{
        content: '';
        z-index: 5;
        position:absolute;
        bottom:0;
        width:100%;
        height:100%;
        background-image: url('../img/schoolday/hands.png');
        background-position: bottom center;
        background-size:contain;
        background-repeat: no-repeat;
      }

      .schoolday-teaser{
        flex-grow: 1;
        z-index:30;
        color:#FFF;
        text-align:center;
        margin-right:30px;
        margin-top:100px;
        h1{
          opacity: .7;
          transform: rotate(-5deg);
          font-size: 5rem;
          line-height:.95em;
        }
      }

      .schoolday-menu{
        position:relative;
        height:100%;
        width:30%;
        overflow:hidden;
        display: flex;
        flex-direction:column;
        justify-content: space-between;

        .schoolday-logo-container{
          display:block;
          height:100%;
          width:85%;
          z-index: 20;

          .schoolday-rapid-logo{
            display:block;
            width:60%;
            margin: 30px auto 40px;

            img{
              width:100%;
              height:auto;
            }
          }

          .schoolday-logo{
            display:block;
            max-width:130px;
            margin: 0 auto;
          }
        }
        &:before{
          z-index: 10;
          content:" ";
          display:block;
          position:absolute;
          width:110%;
          height:110%;
          right: 30px;
          top:-5%;
          transform: rotate(-3deg);
          background: #00794c;
          background: -moz-linear-gradient(left, #00794c 0%, #00602c 100%);
          background: -webkit-linear-gradient(left, #00794c 0%,#00602c 100%);
          background: linear-gradient(to right, #00794c 0%,#00602c 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00794c', endColorstr='#00602c',GradientType=1 );
          border-right:10px solid #FFF;
        }
      }
    }
    .schoolday-content{
      background:#FFF;
      padding: 30px 30px;
    }
  }

  .schoolday-date-row{
    margin: 0 -10px;
    padding: 10px 10px;
    &:nth-child(even){
      background: #f6f6f6;
    }

    .slots{
      .no-slots-open{
        color:#aaa;
      }
      .slot{
        width:10px;
        height:10px;
        margin:2px;
        float:left;
        background: #DDD;
        &.open-slot{ background: #00794c;}
        &.closed-slot{background: #FF0000;}
      }
    }
  }

  .schoolday-ranking-table{
    width:100%;
  }

  .schoolday-gallery-box{
    display:none;
    &:first-child{
      display:block;
    }
  }

  .schoolday-download-row{
    a{
      display:block;
      position:relative;
      &:hover,
      &:focus,
      &:visited,
      &:active{
        background:#F6F6F6;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .language-select {
      margin-top: 0 ;
      display: block;
    }
  }
}

@media(max-width:1199px){
  .schoolday {
    .schoolday-inner {
      .schoolday-header {
        .schoolday-teaser {
          margin-top:50px;
        }
        .schoolday-menu{
            width:40%;
        }
      }
    }
  }
}

@media(max-width:991px){

  .schoolday{
    .schoolday-sponsor-logo {
      max-height: 40px;
    }

    .schoolday-container {
      .schoolday-header {
        height: auto;
        padding-bottom:150px;
        display:block !important;

        .schoolday-teaser{
          h1{
            font-size: 2rem;
            line-height: 2.5rem;
          }
        }

        .schoolday-menu {
          height: auto;
          width: 100%;
          flex-direction: row;
          align-items:center;
          background: #00794c;
          background: -moz-linear-gradient(left, #00794c 0%, #00602c 100%);
          background: -webkit-linear-gradient(left, #00794c 0%,#00602c 100%);
          background: linear-gradient(to right, #00794c 0%,#00602c 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00794c', endColorstr='#00602c',GradientType=1 );
          border-bottom:10px solid #FFF;
          overflow: visible;


          .schoolday-logo-container{
            display: flex;
            width:100%;
            justify-content: space-between;
            align-items: center;
            padding:20px 40px;

            .schoolday-rapid-logo {
              display: block;
              width: 120px;
              z-index: 20;
              margin:0;
            }
            .schoolday-logo {
              z-index: 30;
              width: 100px;
              margin:0;
            }
          }

          &:before{
            display:none;
          }
        }
      }
    }
  }
}


@media(max-width:449px){


  .schoolday {
    /*
    .schoolday-top{
      .language,
      .language-select{
        width:100%;
        text-align:center;
        margin:0;
      }
      .language-select li{
        margin-top:10px;
      }
    }
    */
    .schoolday-container {
      .schoolday-header {
        padding-bottom: 100px;
        .schoolday-menu {
          .schoolday-title {
            h1 {
              font-size: 2.5rem;
            }
          }
          .schoolday-logo-container{
            padding: 20px;
          }
        }

      }
    }
  }
}
