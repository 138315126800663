.content-box {
  .content-box-content {
    border: 1px solid $brand-primary;
    padding: 0 10px;
  }
}

.radio-inline {
  margin-bottom: 0;
  cursor: pointer;
  vertical-align: middle;
}

.control-label {
  font-weight: 700;
  margin-bottom: 5px;

}
.required-star {
  color: #d14d38;
}
