.gamelist{
  .gamelist-select {
    background-color: white;
    width: 100%;
    height: 34px;
    color: #555;
    font-size: 14px;
    border: 1px solid #ccc;

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  }

  .gamelist-table-head {
    background-color: $brand-primary;
    color: white;
    text-transform: uppercase;
    font-size: 15px;
  }

  table.game-list-table {
    margin-bottom: 20px;
    border: 0;
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;

    th {
      background: $brand-primary;
      font-size: 1.1em;
      font-weight: bold;
      padding: 3px 10px;
      color: #fff;
      text-transform: uppercase;
      border-bottom: 0;
    }

    tr td {
      border-top: 0;
      border-bottom: 1px solid #eee;
      vertical-align: middle;
      background: none;

      &.logo,
      &.name {
        border: 0;
      }
    }

    tr.even {
      background: #f9f9f9;
    }

    tr.odd {
      background-color: #fff;
    }

    .odd + .odd td,
    .even + .even td {
      padding-top: 0;
      border-bottom: 1px solid #eee;
    }

    img {
      height: 25px;
      width: auto;
    }

    .score {
      font-size: 1.1em;
    }

    .league {
      width: 10%;
    }

    .round,
    .date {
      width: 20%;
    }
    tr td.logo {
      width: 1%;
      padding-right: 0;
      text-align: center;
    }
    .score {
      width: 15%;
    }

    .name {
      padding-left: 6px;
    }
    .links {
      width: 10%;
    }
  }
}


