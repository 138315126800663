
.top-bar {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 3;
  height: 78px;
  width: 100%;
  background: url('../img/head-on.jpg') top center no-repeat;
  background-size: cover;

  .container {
    position: relative;
    z-index: 1;
  }

  .top-bar-off {
    @include opacity(0);
    transition: opacity 2s;
    position: absolute;
    top: 0;
    left: 0;
    height: 78px;
    width: 100%;
    background: url('../img/head-off.jpg') top center no-repeat;
    background-size: cover;

    &.visible {
      @include opacity(1);
    }
  }

  .top-nav-container {
    margin: 0;
    background: rgba(0, 0, 0, 0.2);
    padding: 5px 10px 5px 0;
    //@include border-top-radius(3px);
    height: 100%;
    @include media-breakpoint-down(md) {
      //padding-left: 105px;
      //margin-left: 105px;
    }
  }

  .language-select,
  .top-bar-nav {
    list-style: none;
    display: inline-block;
    padding: 0;
    font-size: 0.9em;
    font-weight: bold;

    li {
      display: inline-block;
    }

    a {
      color: #fff;
      text-transform: uppercase;
    }
  }

  @include media-breakpoint-down(sm) {
    .language-select,
    .top-bar-nav {
      margin-top: 0;
      display: block;
    }
  }

  .top-bar-nav {
    li {
      margin: 0 0 0 10px;
    }
  }

  .allianz-stadion-logo {
    width: 125px;
    height: auto;
    vertical-align: text-bottom;
  }

  .language-select {
    margin-left: 10px;

    img {
      width: 24px;
      height: auto;
    }
  }
}
