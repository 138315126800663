.shop {

  background:#FFF;

  &.content-box {
    margin: 0;
    padding: 0 50px 30px;

    @include media-breakpoint-down(sm) {
      padding: 0 20px 10px;
    }

    .content-box-title {
      background: #FFFFFF;
      padding: 30px 0 15px;

      display: flex;
      justify-content: space-between;
      align-items: center;
      &:before, &:after {
        display: none;
      }

      h3 {
        padding: 3px 0 0;
        color: #007a4b;
        text-transform: uppercase;
        font-size: 3em;
        @include font-folio;
      }

      a {
        padding: 6px 60px;
        font-size: 1.2em;
        display: block;
        background: #007a4b;
        color: #FFF;
        text-decoration: none;
        border-radius: 5px;
        font-weight: bold;
        text-transform: uppercase;
        @include font-folio;
        transition: background .3s;

        &:hover {
          background: darken(#007a4b, 5%);
        }
      }
    }

    .content-box-footer {
      margin: 10px 0 0;
      padding: 10px 0;

      @include media-breakpoint-down(sm) {
        a {
          font-size: 1.2em;
          display: block;
          text-align: center;
          padding: 6px 60px;
          background: #007a4b;
          color: #FFF;
          text-decoration: none;
          border-radius: 5px;
          font-weight: bold;
          text-transform: uppercase;
          @include font-folio;
          transition: background .3s;

          &:hover {
            background: darken(#007a4b, 5%);
          }
        }
      }
    }

    .content-box-holder {
      &, .content-box-content-radial-bg {
        background: #FFFFFF;
        color: #007a4b;
      }
    }

    &.bg-grey {
      .content-box-title {
        background: #f1f1f1;
      }
      .content-box-holder {
        &, .content-box-content-radial-bg {
          background: #f1f1f1;
        }
      }
    }
  }



  .content-box-holder{
    .content-box-content{
      border:none;
    }
  }

  .shop-slide{
    padding: 0 12px;
  }

  .shop-box{
    margin: 0 0 10px;

    &,
    &:hover,
    &:focus,
    &:active{
      text-decoration:none !important;
    }

    .shop-img{
      transform: scale(1);
      transition: transform .3s;
      margin-bottom:10px;

      img{
        margin: auto;
        width:auto;
        height:auto;
        max-width:100%;
        max-height:100%;
      }
    }

    .shop-text{
      color:#333;
      transition: color .3s;
      text-align:center;

      .shop-text-title,
      .shop-text-price{
        margin: 5px 0 0;
      }

      .shop-text-price{
        color:#007a4b;
        @include font-folio;
      }
    }

    &:hover{
      .shop-img{
        transform: scale(1.05);
      }
    }
  }


  .landing-slick-next,
  .landing-slick-prev{
    position:absolute;
    width:13px;
    height:22px;
    border:none;
    color:#FFF;
    font-size:3em;
    top:50%;
    transform: translateY(-50%);
    outline:none;

    &.slick-disabled{
      opacity:.5;
    }

  }

  .landing-slick-next{
    background: url('../img/landing-chevron-right-dark.png') center center no-repeat transparent;
    right:-90px;

    @include media-breakpoint-down(md){
      right:-30px;
    }
    @include media-breakpoint-down(sm){
      right:-15px;
    }
  }

  .landing-slick-prev{
    background: url('../img/landing-chevron-left-dark.png') center center no-repeat transparent;
    left:-90px;

    @include media-breakpoint-down(md){
      left:-30px;
    }
    @include media-breakpoint-down(sm){
      left:-15px;
    }
  }
}
