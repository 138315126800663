.embed-responsive.youtube-video-link {
  cursor: pointer;
  position: relative;
  display: block;

  .preview-img {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-position: center center;
    background-size: cover;
  }

  .play-btn {
    width: 70px;
    height: 50px;
    margin-left: -35px;
    margin-top: -25px;
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.caption {
  font-size: .9rem;
  line-height: 1.3rem;
}
