#home .standings-calendar,
.standings-calendar {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  .table-responsive {
    width: 100%;

    @include media-breakpoint-down(sm) {
      border: none;
      margin-bottom: 0;
    }
  }

  &.content-box .content-box-content {
    border: none;


    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  }

  .content-box-title {
    background: transparent;

    h3 {
      color: #fff;
      display: block;
    }
  }

  .content-box-content {
    padding: 20px 30px;
  }

  .content-box-holder {
    background: transparent;

    .calendar-table-color {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }


    .table {
      background: #FFF;

      &.outer-table {
        margin-bottom: 0;

        & > tbody > tr {
          &:last-child {
            border-bottom: 1px solid #ddd;
          }

          & > td {
            padding: 0;
          }
        }
      }

      .dates-row {
        font-size: .9em;
        font-weight: bold;
        color: #007a4b;
      }

      img.chevron {
        opacity: 0;
        transition: opacity .2s;
      }

      a {
        display: block;
        position: relative;
        text-decoration: none;
        overflow: hidden;
        color: #333;
        transition: background .2s;
        padding-right: 40px;

        &:after {
          content: "";
          display: block;
          position: absolute;
          background: url('../img/landing-chevron-right-dark.png') top center no-repeat;
          width: 13px;
          height: 22px;
          top: 50%;
          margin-top: -11px;
          opacity: 0;
          right: -100%;
          transition: right .2s, opacity .2s;
        }

        &:focus,
        &:hover {
          background: #f1f1f1;

          img.chevron {
            opacity: 1;
          }

          .inner-table {
            color: #007a4b;
          }

          &:after {
            opacity: 1;
            right: 30px;
          }


          @include media-breakpoint-down(sm) {
            width: 100%;
            padding-right: 0;
            &:after {
              display: none;
            }
          }

        }

      }

      .inner-table {
        background: transparent;
        margin: 16px 0;
        color: #333;

        & > tbody > tr > td {
          border-top: none;
          white-space: normal;

          h5 {
            margin: 0;
            font-size: 1.4em;
          }
        }
      }
    }
  }

  .standings-table {
    table {
      tr {
        border-top: 1px solid #ddd;

        &:last-child {
          border-bottom: 1px solid #ddd;
        }

        td {
          color: #333;
          padding: 5px;
          font-weight: bold;
          text-align: left;

          &.col2 {
            text-transform: uppercase;
          }

          &.col3,
          &.col4 {
            text-align: center;
          }

          &.col5 {
            text-align: right;
          }
        }

        &.highlight {
          td {
            color: #007a4b;
          }
        }
      }

      thead {
        tr {
          td {
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
