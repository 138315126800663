#countdown_timer {
  text-align: center;
  font-size: 0.8em;
  font-weight: bold;
  color: white;

  &.phase2 {
    .countdown_timer_head {
      font-size: 3.1em;
      font-family: 'reproduction';
      margin-bottom: 1rem;

      @media (max-width: 330px) {
        font-size: 2.5em;
      }
    }

    #countdown_timer_wrap {
      display: none;
    }
  }

  .countdown_timer_head {
    font-size: 1.1em;
  }

  #countdown_timer_wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.5em;
    padding-top: 0.5em;

    .countdown_timer_time {
      width: 7em;
      height: 6.5em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: #b2d6c7;
      color: black;
      border-radius: 4px;
      padding: 4px;
      font-size: 0.7em;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 0.5px;

      @media (max-width: 575px) {
        font-size: 0.75em;
      }

      .countdown_timer_time_value {
        font-size: 2em;
        font-weight: bold;
      }
    }
  }

  @media (min-width: 576px) {
    font-size: 0.9em;
  }

  @media (min-width: 768px) {
    font-size: 0.9em;
  }

  @media (min-width: 992px) {
    font-size: 1em;
  }

  @media (min-width: 1200px) {
    font-size: 1.1em;
  }

}


.main-nav .navbar-primary {
  background-color: white;
  border-color: white;
}

.main-nav .navbar-primary.navbar {
  color: #00794c;
}

.main-nav .navbar-primary.navbar .navbar-nav > li > a {
  color: #00794c;
}

.top-bar {
  //height: 108px;
  background: none;

  .top-nav-container {
    margin-top: 10px;
    height: 100%;
    background: none;

  }

  @media (min-width: 768px) {
    margin-top: 0;
  }
  @media (min-width: 992px) {
    margin-top: 35px;
  }


  #countdown_timer {
    padding-top: 1em;

    @media (min-width: 576px) {
      font-size: 0.9em;
    }

    @media (min-width: 768px) {
      font-size: 1em;
    }

    @media (min-width: 992px) {
      font-size: 1em;
      padding-top: 2em;
    }

    @media (min-width: 1200px) {
      font-size: 1em;
    }

    &.phase2 {
      padding-top: 3em;
      padding-bottom: 0.5em;

      @media (max-width: 767.98px) {
        padding-top: 2em;
        min-height: 80px;

      }
    }
  }

  &.phase2 {
    @media (max-width: 767.98px) {
      .language-select,
      .top-bar-nav {
        margin-top: 3em;
        display: inline-block;
      }
    }
    @media (max-width: 576px) {
      .language-select,
      .top-bar-nav {
        margin-top: 1em;
        display: block;
      }
    }
    @media (max-width: 330px) {
      .language-select,
      .top-bar-nav {
        margin-top: 0;
      }
    }
  }
}

//.main-nav .navbar-holder {
//  margin-top: 178px;
//
//  @media (min-width: 768px) {
//    margin-top: 158px;
//  }
//
//  @media (min-width: 992px) {
//    margin-top: 148px;
//  }
//
//}

img.allianz-stadion-logo {
  display: none !important;
}

.is-scrolled {
  #countdown_timer {
    display: none;
  }
}
