.news-ticker {
  background: #f1f1f1;

  .content-box-holder,
  .content-box-title {
    background: #f1f1f1 !important;
  }

  .news-box {
    background: #fff !important;
    margin: 0;
    transition: background .3s;
    text-decoration: none !important;
    display: block;
    height: 100%;

    &:hover {
      background: $primary-dark !important;

      .ticker-text {
        color: #fff;
      }
    }
  }

  .news-ticker-img {
    display: block;
    position: relative;
    padding-bottom: percentage(550 / 700);
    background-color: #ccc;
    background-size: cover;
    background-position: center center;

    img {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 4;
    }
  }

  .news-ticker-date {
    background: 0 0;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 0 10px 10px;
    border-radius: 5px 0 0;
    color: #fff;
    font-size: 1.2em;
    font-family: 'Folio W01 Bold Condensed', Helvetica, Arial, sans-serif;
    font-weight: 700;
    z-index: 6;
  }

  .ticker-text {
    text-transform: none !important;
    padding: 10px;
    transition: color .3s;
    color: #283c28;
  }

  .news-ticker-ad {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    background: #fff;
    align-items: center;
    justify-content: center;
  }
}
