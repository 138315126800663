.countdown-box-2018{
  &.content-box-2018 {
    .content-box-title {
      font-family: 'Folio W01 Bold Condensed',Helvetica,Arial,sans-serif;
      font-size: 22px;

      a {
        font-size: 14px;
        text-transform: uppercase;
      }
    }
  }

  .countdown-background {
    background-size: contain;
    background-repeat: no-repeat;
    height: 160px;

    .countdown-box {
      padding-top: 35px;

      .countdown {
        font-family: 'Folio W01 Bold Condensed', Helvetica, Arial, sans-serif;
        font-size: 40px;
        color: white;
        max-height: 45px;
      }
      .countdown-titles {
        color: white;
      }
    }
  }
}





