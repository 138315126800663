.ad-box {
  border: 1px solid $brand-primary;
  padding: 10px 0;
  background: #fff;
  margin: 0 0 10px;
  text-align: center;

  &.no-border {
    border: none;
    padding: 0;
  }

  iframe {
    display: block;
    margin: 0 auto;
  }

  & > div {
    display: block;
    margin: 0 auto;
  }

  @include media-breakpoint-down(xs) {
    border: none;
    margin: 10px -15px;
    background: transparent;
  }
}

.side-ad {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 950;
  margin-left: 5px;
  margin-top: 5px;
}
