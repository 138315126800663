.calender {
  .calender-headline {
    font-weight: 500;
    font-size: 25px;
  }

  .table {
    thead {
      th {
        border-bottom: none;
        background-color: $brand-primary;
        color: white;
        padding-top: 2px;
        padding-bottom: 2px;
        border: 1px solid #ddd
      }
    }
    tbody {
      border-bottom: 1px solid #ddd;
      tr {
        td {
          border-left: 1px solid #ddd;
          border-right: 1px solid #ddd;
          border-top: none;
        }
        .calender-event {
          background-color: #bc2300;
          font-size: 11px;
          max-width: 80px;
          min-height: 40px;
          color: white;

          .time {
            font-weight: 500;
          }
        }
        .today {
          background: #e4f1ec;
        }
      }
    }
  }
}
