.quote-box {
  background: #f6f6f6;
  .quote {
    font-size: 15px;
    position: relative;

    &:after,&:before {
      display: block;
      content: '';
      position: absolute;
      width: 25px;
      height: 19px;

    }
    &:before {
      background: url("../img/quote-2.png");
      left: 0;
      top: 0;
    }
    &:after {
      background: url("../img/quote-1.png");
      right: 0;
      top: 0;
    }
  }
  .quote-name {
    font-style: italic;
    font-size: 14px;
  }
}
