.tv-box{
  .tv-video-box{
    background:#f1f1f1;
    margin-bottom:10px;


    &:last-child {
      &.tv-video-box-sm {
        margin-bottom: 0;
      }
    }

    .tv-video{
      width:100%;
      background:#cccccc;
      position: relative;
      padding-bottom: 56.25%; /* Default for 1600x900 videos 16:9 ratio*/
      padding-top: 0;
      height: 0;
      overflow: hidden;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .b-player-error-screen .error-container .error-wrapper {
        font-size: $font-size-base;
        line-height: $line-height-base;
        width: 90%;
        padding: 20px;

        .title {
          font-size: 16px;
          margin-bottom: 10px;
        }

        .button-wrapper {
          margin-top: 10px;
          padding: 0;
        }

        .b-btn {
          margin-bottom: 0 !important;
        }
      }
    }

    .tv-video-text{
      padding:20px;
      color:#333;

      h5{
        color: #007a4b;
        @include font-folio;
        font-size:1.2em;
        text-transform: uppercase;
      }
      h3{
        margin:0 0 20px;
        font-size:1.6em;
      }
      p{
        line-height:1.8em;
        color:#626f62;
      }
    }
  }

  .tv-video-box-sm{
    .tv-video-text{
      padding:10px 20px;
    }
  }
}
