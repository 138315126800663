.video-container {
  position: relative;
  padding-bottom: 56.25%;

  .fantv-player-embed,
  .fantv-player {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin-bottom: 0;
  }

  .b-player-error-screen .error-container .error-wrapper {
    font-size: $font-size-base;
    line-height: $line-height-base;
    width: 80%;

    .title {
      font-size: 16px;
      margin-bottom: 10px;
    }

    .button-wrapper {
      margin-top: 10px;
    }
  }
}
