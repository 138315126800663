.content-box {
  a {
    &:hover {
      text-decoration: none;
    }

    .content-box-title {
      h3 {
        font-family: 'Roboto', Helvetica, Arial, sans-serif;
        font-size: 17px;
      }
    }
  }
}
