/*
//Include to add Sprites to your CSS File
@import "sprites/sprite-...";
@include sprites($spritesheet-sprites);
//@include retina-sprites($retina-groups); //Include if "enable_retina_sprites" is true
*/

@mixin font-folio{
  font-family: 'Folio W01 Bold Condensed', 'Helvetica', 'Arial', sans-serif;
}


@mixin custom-gradient($color){
  @include gradient-x($color, darken($color, 2%));
}
