body {
  background: url('../img/background-main-2.jpg') center center no-repeat fixed;
  background-size: cover;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto !important;
  overflow-x: hidden;

  &.hospitality-body {
    //background: url('../img/bg-hospitality.jpg') center center;

    .content {
      background: rgba(255, 255, 255, 0.7);
    }

    .content-padding {
      //padding: 0;
    }

    .page-content-header {
      //background: url('../img/hospitality-bg-top.jpg') center center;
      background-size:cover;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      & > .container > .row {
        background: #fff;
      }
    }
  }
}

html {
  overflow-x: hidden;
}

*{
  min-height: 0;
  min-width: 0;
}

.content-container {
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
}

.footer-logo {
  display: block;

  .hover {
    display: none;
  }

  &:hover {
    img {
      display: none;
    }

    .hover {
      display: block;
    }
  }
}

.content {
  min-height: 500px;

  h4 {
    margin-top: 0;
  }
}

.header-img {
  margin: 0 -5px;
}

.bg-white{
  background-color:#FFF;
}

.sidebar-hidden{
  display:inherit;
}

.sidebar-only{
  display:none;
}

.sidebar{
  .hidden-sidebar{
    display:none;
  }
  .sidebar-only{
    display:inherit;
  }
}

.btn {

  border-width: 1px;

  &.btn-cta{
    background: $body-bg;

    &:hover,
    &:focus,
    &.focus {
      background: darken($body-bg, 10%);
    }
  }
}

.form-send-success {
  display: none;
}

.accordion,
.col-sidebar,
.left-sidebar{
  .spacer{
    display:none;
  }
}

.content-cols {
  display: flex;
  flex-direction: row;

  .col-main {
    flex: 1;
  }

  .col-sidebar {
    flex-basis: 330px;
    padding-left: $grid-gutter-width;
  }

  &.no-gutters {
    .col-sidebar {
      padding-left: 0;
    }
  }

  @include media-breakpoint-down(md){
    display: block;

    .col-sidebar {
      padding-left: 0;
    }
  }
}

#page,
#news{
  .news-date {
    font-size: 1.2em;
    font-style: italic;
    color: #888;
  }

  .news-keywords {
    font-size: 85%;
    font-weight:bold;
    color: $brand-primary;
  }
}


.share-buttons-holder {
  position: fixed;
  top: 50%;
  left: 0;
  z-index: 1000;

  ul {
    @include border-right-radius(5px);
    background: rgba(0, 0, 0, 0.5);
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: block;
    }
  }

  a {
    @include opacity(0.8);
    display: inline-block;
    padding: 5px;
    color: #fff;
    font-size: 1.5em;

    &:hover {
      @include opacity(1);
      //padding-left: 15px;
    }
  }

  .share-btn-facebook:hover {
    color: #3B5998;
  }

  .share-btn-tiktok:hover {
    color: #111;
    filter: drop-shadow(1px 0px 0px #FD3E3E) drop-shadow(-1px -1px 0px #4DE8F4);
    padding-left: 6px;
    padding-top: 6px;
    padding-bottom: 4px;
    padding-right: 4px;
  }

  .share-btn-twitter:hover {
    //color: #5EA9DD;
    color: #fff;
  }

  .share-btn-google:hover {
    color: #DC4A38;
  }

  .share-btn-youtube:hover {
    color: #DC4A38;
  }

  .share-btn-instagram:hover {
    color: #517FA4;
  }
}

iframe {
  border: none;
}

.bg-black {
  background: #000;
}

.no-col-padding{
  margin-left: 0;
  margin-right: 0;
  & >[class*=" col-"], .no-col-padding>[class^=col-] {
    padding-left: 0;
    padding-right: 0;
  }
}

#share-buttons-holder{
  @include media-breakpoint-down(md){
    display:none;
  }

}

#home-hero{
  .home-news-container {
    padding:0;
    background-position:center center;
    background-size:cover;
    background-image: url('../img/der-skrapid.jpg');

    a {
      position: relative;
      display: block;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      width: 100%;
      padding-bottom: 50%;

      img {
        z-index: 1;
      }

      &:after {
        position: absolute;
        width: 100%;
        height: 50%;
        bottom: 0;
        left: 0;
        content: '';
        //background: -moz-linear-gradient(top, transparent 50%, rgba(0, 0, 0, .75) 100%);
        //background: -webkit-linear-gradient(top, transparent 50%, rgba(0, 0, 0, .75) 100%);
        //background: linear-gradient(to bottom, transparent 50%, rgba(0, 0, 0, .75) 100%);
        background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 30%, rgba(0,0,0,0.8) 100%);
        background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 30%,rgba(0,0,0,0.8) 100%);
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 30%,rgba(0,0,0,0.8) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 );
        z-index: 5;
      }

      .home-news-teaser {
        position: absolute;
        z-index: 10;
        right: 20px;
        left: 20px;

        &.featured {
          left: 60px;
          @include media-breakpoint-down(md) {
            left: 20px;
          }
        }

        bottom: 10px;
        @include media-breakpoint-down(sm) {
          bottom: 20px;
        }
        color: #FFF;

        h1, h2, h3, h4, h5, h6 {
          text-transform: uppercase;
          @include font-folio;
        }

        h2 {
          font-size: 42px;
          @include media-breakpoint-down(sm) {
            font-size: 24px;
          }
        }
        h3 {
          font-size: 24px;
        }
      }
    }

    $home-news-margin: 5px;

    .featured-container {
      padding-right: $home-news-margin;
      a {
        padding-bottom: calc(50% + 7.5px);
      }

      @include media-breakpoint-down(md) {
        padding-right: 0;
        margin-bottom: $home-news-margin;

        a {
          padding-bottom: 50%;
        }
      }
    }

    .secondary-0 {
      margin-bottom: $home-news-margin;
    }

    @include media-breakpoint-down(md) {
      .secondary-0 {
        margin-bottom: 0;
        padding-right: $home-news-margin;
      }
    }

    @include media-breakpoint-down(sm){
      .secondary-0{
        padding-right: 0;
        margin-bottom: $home-news-margin;
      }
    }
  }

  .mobile-slider{
    margin-bottom:6px;

    .main-slider{
      a{
        display: block;
        padding-bottom: percentage(406/798);
        position: relative;
        background-size: cover;
        background-position: center center;

        &:after{
          position:absolute;
          width:100%;
          height:50%;
          bottom:0;
          left:0;
          content:'';
          background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 30%, rgba(0,0,0,0.8) 100%);
          background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 30%,rgba(0,0,0,0.8) 100%);
          background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 30%,rgba(0,0,0,0.8) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 );
          z-index:5;
        }
      }

      .home-news-teaser{
        position:absolute;
        z-index:10;
        bottom: 20px;
        left:10px;
        right:10px;
        color:#FFF;

        h1,h2,h3,h4,h5,h6{
          @include font-folio;
        }

        h3{font-size:24px;}
      }

      .slick-dots {
        text-align: center;
        margin: 0 0 10px;

        li {
          width: 15px;
          height: 15px;
          margin: 0 5px;

          button:before {
            width: 15px;
            height: 15px;
            content: '';
            border: 2px solid #FFF;
            border-radius: 50%;

            @include opacity(1);
            background: none;
          }

          &.slick-active button:before {
            @include opacity(1);
            background: #FFF;
          }
        }
      }
    }
  }
}

.slick-arrow:hover,
.slick-arrow:focus,
.slick-arrow:active {
  outline:none;
}

#page-hero {
  .page-hero-container {
    background-position: center center;
    background-size: cover;
    background-image: url('../img/der-skrapid.jpg');
    padding-bottom: percentage(406 / 1140);
  }
}

.action-btn {
  font-weight: 700;
  font-size: 1.2em;
  font-family: 'Folio W01 Bold Condensed',Helvetica,Arial,sans-serif;
}

.yt .section-title{
  @include font-folio;
  font-size: 1.6em;
  text-transform:uppercase;
  background-color: #00794c;
  color:#FFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin:0;
}


.btn-yellow,
.btn-yellow:visited{
  background:#feeb19;
  color:#015636;
  border-color:#feeb19;
  transition: color .2s, background .2s, border;
  @include font-folio;
  text-transform: uppercase;
  font-size: 1.2em;
  padding-left:25px;
  padding-right:25px;
}


.btn-yellow:hover,
.btn-yellow:active,
.btn-yellow:focus{
  background:darken(#feeb19,20%);
  border-color:darken(#feeb19,20%);
  color:#000;
}

.landing-vs {
  background-color: #096;
  .game-vs-logo{
    width:30%;
    font-weight:bold;
    color:#FFF;
    text-align:center;

    .game-vs-logo-element {
      width: 100%;
      max-width: 95px;
      padding-bottom: 100%;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      margin:0 auto 10px;
    }
  }

  .team-name {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .game-vs-center {
    font-size: 3em;
    font-weight: bold;
    text-align: center;
    color: #fff;
    padding: 0 15px;
    @include font-folio;

    .game-vs-center-time {
      font-size: 0.5em;
      margin-top: -15px;
    }
  }

  .landing-countdown,
  .landing-game-info{
    color:#FFF;
    font-weight:bold;
  }

  @include media-breakpoint-down(md) {
    text-align:center;

    .game-vs-center {
      font-size: 2em;
    }

    .games-box-game{
      margin-bottom:25px;
    }

    .landing-game-info,
    .landing-countdown{
      text-align:center;
    }

    .content-box-holder .d-flex.justify-content-stretch{
      justify-content: center;
    }

    .landing-games-game-container{
      padding:0 40px;
    }
  }

  .landing-slick-next,
  .landing-slick-prev{
    position:absolute;
    width:13px;
    height:22px;
    border:none;
    color:#FFF;
    font-size:3em;
    top:50%;
    transform: translateY(-50%);
    outline:none;
    z-index: 10;

    &.slick-disabled{
      opacity:.5;
    }
  }

  .landing-slick-next{
    background: url('../img/landing-chevron-right.png') center center no-repeat transparent;
    right:10px;
  }

  .landing-slick-prev{
    background: url('../img/landing-chevron-left.png') center center no-repeat transparent;
    left:10px;
  }
}

.footer {
  background: #00794c;
  -webkit-box-shadow: 0 -4px 4px rgba(0,0,0,.08);
  box-shadow: 0 -4px 4px rgba(0,0,0,.08);
  color: #fff;
  padding: 10px 0 20px;

  .footer-row {
    padding-top: 10px;
    border-top: 1px solid #00935c;
  }

  .footer-links {
    margin: 0 -10px;
    padding: 0;
    list-style: none;
    font-size: 0.85em;
    line-height: 12px;

    li {
      display: inline-block;
      border-right: 1px solid #fff;
      padding: 0 10px;

      a {
        color: #fff;

        &:hover, &:focus {
          color: #fff;
        }
      }

      &:last-child {
        border-right: none;
      }
    }
  }
}

.table-lines-container table,
.table-lines {
  border-top: 1px solid #fff;
  border-spacing: 0;
  border-collapse: separate;
  width: 100%;

  td {
    border-bottom: 1px solid #fff;
  }
}

.player-links {
  padding: 0;
  margin: 10px 0;
  list-style: none;
  background: #6b6b6b;
  width: 100%;
  display: table;

  li {
    display: table-cell;
    padding: 5px;
    text-align: center;
    width: 50%;

    a {
      display: block;
      border-right: 1px solid #fff;
      padding: 3px 0;
      color: #fff;

      &:hover {
        color: #fff;
      }
    }

    &:last-child a {
      border: none;
    }
  }
}

.image-caption {
  line-height: 1.3rem;
  font-size: 1rem;
  padding: 2px 5px;
  background: rgba(0,0,0,.04);
  font-style: italic;
}

.wysiwyg {
  p {
    margin-bottom: 10px;
  }

  table {
    border-bottom: 1px solid #eee;
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;

    th,
    td {
      padding: 4px 5px;
      border-top: 1px solid #eee;
    }

    tr {
      background: #f9f9f9;
    }

    tr:nth-child(odd) {
      background-color: #f2f2f2;
    }

    th {
      background: #fff;
      border-bottom: 1px solid #eee;
    }
  }
}

.slick-initialized .slider-image-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center center;
}

.filter-box {
  .filter-title {
    padding: 10px 0;
    font-weight: bold;
    font-size: 1.2em;
  }

  .filter-select {
    cursor: pointer;
    position: relative;
    background: #f2f2f2;
    margin: 10px 0 0;
    padding: 2px 25px 2px 25px;
    overflow: hidden;

    &.disabled {
      .filter-legend-color {
        background: #ddd !important;
      }
    }
  }

  a.calendar-link {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: #ddd;
    color: $brand-primary;
    padding: 3px 5px;

    &:hover {
      color: $brand-primary;
      background: #ccc;
    }
  }

  .filter-legend-color {
    display: block;
    width: 20px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
  }
}

.rapid-table {
  &>thead>tr>th {
    background: $brand-primary;
    color: #fff;
    text-transform: uppercase;
    padding: 5px 8px;
    border-bottom: 0;
    font-size: 1.1em;
  }
}

table.green-header-table {
  &>thead>tr>th {
    background: $brand-primary;
    font-size: 1.1em;
    font-weight: bold;
    padding: 3px 10px;
    color: #fff;
    text-transform: uppercase;
    border: 0;
  }
}

.badge-warning {
  color: #fff;
}

.network-table {
  .network-table-header {
    color: #00794c;
    border-top: 3px solid #00794c;
    background: #eee;
    font-size: 1.1em;
    padding: 7px 10px;
    font-weight: bold;
  }

  .network-table-content {
    margin-bottom: 20px;
  }

  .network-table-row {
    border-bottom: 1px solid #ddd;
    padding: 7px 10px;
  }
}

.games-home-slider {
  display: none;
  position: relative;

  &.slick-initialized {
    display: block;
  }
}

.display-mobile.d-flex {
  display: none !important;

  @media only screen and (max-width: 991px) {
    display: flex !important;
  }
}
