.slick-slide {
  position: relative;
}

.slider-content {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 55%;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 15px;

  h3 {
    @include font-folio;
    font-size: 1.8em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-top: 0;
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  p {
    margin-bottom: 0;
    font-weight: bold;
  }

  @include media-breakpoint-down(lg){
    left: 40%;
  }

  @include media-breakpoint-down(md){
    left: 40%;
  }

  @include media-breakpoint-down(sm){
    left: 0;

    p {
      display: none;
    }
  }
}

.slick-slider {
  margin-bottom: 0;
}

.slick-slide img {
  border: 0;
}

.main-slider {
  .custom-slick-prev,
  .custom-slick-next {
    display: none !important;
    position: absolute;
    top: 50%;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    border: 0;
    margin-top: -20px;
    padding: 10px;

    &:hover {
      background: #000;
    }
    &:focus {
      outline: 0;
    }

  }
  .custom-slick-prev {
    left: 0;
  }
  .custom-slick-next {
    right: 0;
  }

  .slick-dots {
    bottom: 0;
    margin-left: 10px;
    text-align: left;
    margin-bottom: 5px;

    li button:before {
      color: #fff;
      font-size: 15px;
    }

    li.slick-active button:before {
      color: #fff;
    }
  }
}

.slider-side-link {
  margin: 4px 0 0 4px;
  text-transform: uppercase;
  color: #fff;
  padding: 5px;
  cursor: pointer;
  height: 28px;
  font-weight: bold;
  display: block;
  background: #c00;
  text-align: center;
  @include gradient-y(#c00, #900);
  text-shadow: 0 -1px 0 rgba(0,0,0,0.5);

  &:hover {
    color: #fff;
  }

  &.blue {
    background: #0B5181;
    @include gradient-y(#0B5181, darken(#0B5181, 5%));
    text-shadow: 0 -1px 0 rgba(0,0,0,0.5);
  }
}

.slider-side-box {
  margin: 4px 0 0 4px;
  background: darken($brand-primary, 7%);
  text-transform: uppercase;
  color: #fff;
  padding: 5px;
  cursor: pointer;
  height: 77px;
  font-weight: bold;

  &:first-child {
    margin-top: 3px;
  }

  @include media-breakpoint-down(md){
    height: 55px;

    &:first-child {
      margin-top: 4px;
    }
  }

  &.active {
    background: lighten($brand-primary, 5%);
    position: relative;

    &:after {
      $size: 15px;
      content: ' ';
      position: absolute;
      top: 50%;
      right: 100%;
      margin-top: -$size;
      border: $size solid transparent;
      border-left: 0;
      border-right: $size solid lighten($brand-primary, 5%);
    }
  }

  @include media-breakpoint-down(sm){
    height: auto;
    margin: 5px 0;

    &.active:after {
      display: none;
    }
  }
}


.main-slider,
.main-slider .slick-slide {
  height: 406px;

  @include media-breakpoint-down(md){
    height: 311px;
  }

  @include media-breakpoint-down(md){
    height: auto;
  }
}

.main-slider {
  overflow: hidden;
}

.slick-dots {
  li {
    width: 27px;
    height: 13px;
    margin: 0 -1px;

    button:before {
      width: 27px;
      height: 13px;
      content: '';
      @include opacity(1);
      background: url('../img/dot-empty.png') no-repeat;
    }

    &.slick-active button:before {
      @include opacity(1);
      background: url('../img/dot-full.png') no-repeat;
    }
  }
}

.box-slide-holder {
  text-align: center;

  .box-slide {
    .text {
      margin-top: 30px;
      min-height: 40px;
      font-size: 1.1em;
      padding: 0 10px;
    }
    .image {
      display: flex;
      justify-content: flex-end;

      .slider-image {
        background-size: cover;
        background-position: center center;
        @include border-radius(50%);
        width: 80px;
        height: 80px;
        border: 2px solid #ddd;
        background: #fff;
      }
    }
    .name {
      font-weight: bold;
      color: $brand-primary;
      text-transform: uppercase;
    }
    .company {
      font-style: italic;
    }
  }

  .slider-table {
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;

    td {
      padding: 10px 8px;
    }
  }
}

.box-slick-prev,
.box-slick-next {
  background: transparent;
  border: none;
  color: #fff;
  position: absolute;
  top: -30px;
  right: 0px;
  outline: none;
  padding: 5px 8px;

  &.slick-disabled {
    @include opacity(0.3);
  }
}

.box-slick-prev {
  right: 25px;
}

