/* ----------------------------------------------------------------------------- */


/* ------------- GENERAL ---------------- */
header {
  z-index: 15;
  position: relative;
  display: block;
}

.main-nav {
  .navbar-toggler:focus {
    outline: none;
  }

  .navbar-toggler-icon {
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .icon-bar {
      background-color: $brand-primary;
      display: block;
      width: 22px;
      height: 2px;
      border-radius: 1px;

      &+.icon-bar {
        margin-top: 4px;
      }
    }
  }

  .header-wien-energie {
    width: 115px;
    height: auto;
    vertical-align: top;

    @include media-breakpoint-down(xs) {
      margin-top: 6px;
      width: 90px;
    }
  }

  .wien-energie {
    float: right;
    margin-left: 5px;
    display: flex;
    align-items: flex-start;
  }

  .energy-switch {
    display: inline-block;
    cursor: pointer;
    margin-right: 3px;

    img {
      width: 30px;
      height: auto;
      display: inline-block;

      @include media-breakpoint-down(xs) {
        margin-top: 6px;
        width: 23px;
      }
    }

    &.off {
      .energy-switch-on {
        display: none;
      }

      .energy-switch-off {
        display: inline-block;
      }
    }

    .energy-switch-off {
      display: none;
    }
  }


  .navbar-brand-logo {
    margin-top: -65px;
    @include transition(margin 0.2s);

    img {
      width: 100px;
      @include transition(width 0.2s);
    }
  }

  .scr-logo {
    width: 100px;
    height: auto;
    margin-top: -42px;
    position: relative;
    z-index: 1001;

    &.logo-landing-mobile {
      margin: 20px 0px;
    }
  }

  .navbar-primary{
    background-color: $primary;
    border-color: $primary-dark;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
    z-index: 4;

    &.navbar {
      border-bottom: 0;
      color: #FFF;
      padding: 0;

      .container {
        padding: 0;
      }

      .header-container {
        position: relative;
        .navbar-outer-container {
          width: 100%;
        }
      }
      .navbar-brand {
        height: 78px;
        width: 105px;
        margin-right: 0;
        z-index: 15;
      }

      .main-navbar {
        z-index: 10;
      }

      .navbar-nav {
        flex-grow: 1;


        & > li {
          position: static;

          & > a {
            color: #FFF;
            text-transform: uppercase;
            padding-left: 9px;
            padding-right: 9px;
            @include font-folio;
            font-size: 1.5em;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            outline: none;
            white-space: nowrap;

            @media (min-width: $grid-float-breakpoint) {
              padding-top: 28px;
              padding-bottom: 19px;
            }
          }

          &:hover,
          &.active {
            color: #fff;
            background-color: #00603c;

            & > a {
              outline: none;
              color: #FFF;
            }
          }
        }
      }
    }

    .dropdown-menu{
      left: 0;
      right: 0;
      @include border-radius(0);
      border: 0;
      color: #fff;
      padding: 20px 20px;
      margin-top: 0;

      & > li > a {
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;

        &:focus,
        &:hover {
          background: transparent;
          text-decoration: underline;
        }
      }
    }

    &.is-fixed {
      left: 0;
      margin-top: 0;
      position: fixed;
      right: 0;
      top: 0;

      .navbar-brand {
        .scr-logo {
          margin: 0 0 0 25px;
          width: 50px;
        }
      }

      .header-wien-energie {
        margin-top: 6px;
        width: 90px;
      }

      .energy-switch {
        img {
          margin-top: 6px;
          width: 23px;
        }
      }
    }
  }

  .navbar-holder {
    margin-top: 78px;
    @include transition(background 0.3s);

    .navbar-brand {
      width: 112px;
    }

    .scr-logo {
      @include transition(width 0.2s, margin 0.2s);
    }
  }
}

.header-band {
  position: absolute;
  left: -90px;
  top: 100%;
  display: block;

  @include media-breakpoint-down(lg) {
    display: none;
  }
}

/* ------------- Breakpoint UP ---------------- */

@include media-breakpoint-up(xl) {
  flex-direction: row;
  flex-wrap: nowrap;

  .mega-nav {
    .dropdown {
      position: static;

      .dropdown-menu {
        top: 0;
        left: 0;
        right: 0;
        border-radius: 0;
        margin: 0;
        padding: 0;
      }
    }

    .mega-menu-img {
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center center;
      min-height: 240px;
    }

    .mega-nav-contact-box {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      padding: $spacer;
      font-size: 1.5rem;
      color: $white;
      line-height: 1.7rem;

      .btn-outline-light {
        color: $white;
      }
    }

    .mega-nav-link {
      display: block;
      text-transform: uppercase;
      color: $body-color;
      font-weight: $font-weight-bold;
      margin-bottom: $spacer / 2;
    }
  }
}

@include media-breakpoint-up(lg){
  .main-nav {
    li.nav-item{
      a.dropdown-toggle {
        position:relative;
        &:after {
          content: '';
          display:none;
          position: absolute;
          top: 100%;
          left: 50%;
          margin-left: -10px;
          border: 10px solid transparent;
          border-bottom: 0;
          border-top: 10px solid #00603c;
          z-index: 1001;
        }
      }

      &.show{
        a.dropdown-toggle {
          &:after{
            display:inherit;
          }
        }
      }
    }


    .navbar-outer-container {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      justify-content: space-between;
      position: relative;


    }


    .navbar {
      border-bottom: 0;
      @include box-shadow(0 4px 4px rgba(0, 0, 0, 0.08));


    }

    .navbar-nav > li > a {
      text-transform: uppercase;
      padding-left: 10px;
      padding-right: 10px;
      @include font-folio;
      font-size: 1.5em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      @include media-breakpoint-up(md) {
        padding-top: 30px;
        padding-bottom: 23px;
      }
    }

    .navbar-primary{
      .dropdown-menu {
        background: rgba(26, 68, 44, 0.9);
        min-height: 406px;

        & > li {
          width: 25%;
          float: left;
          position: relative;
          margin-bottom: 15px;

          & > a {
            display: block;
            padding: 3px 20px;
            clear: both;
            white-space: nowrap;
            color: #fff;
            text-transform: uppercase;
            font-weight: bold;
          }

          &:before {
            content: ' ';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 10px;
            width: 1px;
            background: #fff;
          }

          ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
              margin-bottom: 5px;

              &.active > a {
                text-decoration: underline;
              }
            }

            a {
              display: inline-block;
              padding-left: 30px;
              color: #fff;
            }
          }
        }

        & > .active > a,
        & > .active > a:focus,
        & > .active > a:hover {
          text-decoration: underline;
          background: transparent;
        }

        &.players {
          & > li > ul > li > a {
            color: #fff;
            text-transform: uppercase;
            font-weight: bold;

            &:focus,
            &:hover {
              background: transparent;
              text-decoration: underline;
            }
          }

          .player-list-holder {
            float: left;
            width: 75%;

            .game-list-element,
            .player-list-element {
              float: left;
              width: 33.3333334%;

              h4 {
                font-size: 1em;
                color: #fff;
                text-transform: uppercase;
                font-weight: bold;
                margin: 0 0 5px;
              }

              ul {
                position: relative;
                margin-bottom: 20px;

                &:before {
                  content: ' ';
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  left: 10px;
                  width: 1px;
                  background: #fff;
                }

                li {
                  margin-bottom: 2px;
                  position: relative;

                  img {
                    position: absolute;
                    left: -66px;
                    top: -32px;
                    display: none;
                    border: 1px solid #fff;
                  }

                  &:hover img {
                    display: block;
                  }
                }

                a {
                  display: inline-block;
                  padding-left: 30px;
                  color: #fff;
                }
              }
            }
          }

          .games-list-holder {
            float: left;
            width: 75%;

            h4 {
              font-size: 1em;
              color: #fff;
              text-transform: uppercase;
              font-weight: bold;
              margin: 0 0 5px;
            }

            .games-menu-element {
              margin-bottom: 10px;
              display: block;
              cursor: pointer;
              background: rgba(0, 0, 0, 0.2);
              color: #fff;

              &:hover {
                color: #fff;
                text-decoration: none;
                background: rgba(0, 0, 0, 0.3);
              }

              .games-menu-element-header {
                background: rgba(0, 0, 0, 0.2);
                font-size: 1.1em;
                font-weight: bold;
                text-align: center;
                padding: 5px 10px;
              }
            }

            .game-list-element {
              float: left;
              padding-right: 15px;
              width: 50%;
              @include clearfix;

              .game-info {
                background: rgba(0, 0, 0, 0.2);
                padding: 5px 10px;
                margin-top: 5px;
              }

              .game-vs-table {
                position: relative;
                margin-top: 30px;
                width:100%;

                .game-vs-image {
                  width: 32%;
                  img {
                    margin-top: -18%;
                    display: inline-block;
                    width: 60%;
                    height: auto;
                  }
                }

                .game-vs-center {
                  font-size: 3.5em;
                  font-weight: bold;
                  text-align: center;
                  color: #fff;
                  padding: 0 10px;
                  @include font-folio;
                }
              }
            }
          }

          & > li {
            width: 100%;
            float: none;

            &:before {
              display: none;
            }

            > ul {
              float: left;
              width: 25%;

              li {
                margin-bottom: 15px;
                position: relative;

                &:before {
                  content: ' ';
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  left: 10px;
                  width: 1px;
                  background: #fff;
                }
              }

              a {
                padding-left: 30px;
                color: #fff;
              }
            }
          }
        }
      }
    }


    .content-holder {
      background: url(../img/background-main-2.jpg) center center no-repeat fixed;
      background-size: cover;
      position: relative;
      z-index: 10;
    }

    .hospitality-body .content-holder {
      //background: url(../img/bg-hospitality.jpg);
    }
  }
}


/* ------------- Breakpoint DOWN ---------------- */
@include media-breakpoint-down(lg){

  .main-nav{
  }
  .dropdown-menu {
    background: #00794c;
    & > li {

      &:before {
        content: ' ';
        background: transparent;
        width: 0;
      }
    }
  }
}


@include media-breakpoint-down(md) {
  .scr-logo {
    margin-left: 5px;
  }

  .main-nav{
    .navbar-primary {
      .dropdown-menu {
        padding: 5px 20px;
      }
    }
    .main-navbar {
      max-height: 80vh;
      max-height: calc(100vh - 64px);
      overflow-y: auto;
      margin-top: 20px;
      .dropdown-menu {
        & > li {
          & > a {
            &:focus,
            &:hover {
              background: transparent;
              text-decoration: underline;
            }
          }

          ul {
            list-style: none;
            margin: 0;
            padding: 0;
            margin-bottom: 15px;
            margin-top: 5px;

            & > li {
              margin-bottom: 5px;
            }

            a {
              padding-left: 25px;
              color: #fff;
            }
          }
        }

        &.players {
          & > li ul a {
            padding-left: 25px;
            color: #fff;
            text-transform: uppercase;
            font-weight: bold;

            &:focus,
            &:hover {
              background: transparent;
              text-decoration: underline;
            }
          }

          .games-list-holder,
          .player-list-holder {
            display: none;
          }
        }
      }
      .navbar-nav {
        margin-bottom: 40px;
       .dropdown-toggle {

         &:after {
           display: none;
         }
       }
      }
    }
  }
}
