.links {
  background:#FFF;


  .content-box-holder{
    .content-box-content{
      border:none;
    }
  }

  .links-box{
    position:relative;
    display:block;
    margin: 0 0 10px;
    text-decoration:none;
    width:100%;
    padding-bottom:75%;
    overflow:hidden;

    .background-image{
      position:absolute;
      width:100%;
      top:0;
      bottom:0;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      transform: scale(1);
      transition: transform .3s;
      z-index: 4;
    }

    &:hover{
      .background-image{
        transform: scale(1.1);
      }
    }


    &:after {
      content:'';
      position: absolute;
      width: 100%;
      top:0;
      bottom: 0;
      left: 0;
      right: 0;
      background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 30%, rgba(0,0,0,0.8) 100%);
      background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 30%,rgba(0,0,0,0.8) 100%);
      background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 30%,rgba(0,0,0,0.8) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 );
      z-index: 5;
    }



    .links-text{
      color:#333;
      transition: color .3s;
      position:absolute;
      bottom:20px;
      left:20px;
      right:20px;
      z-index: 6;

      .links-text-title{
        @include font-folio;
        text-transform: uppercase;
      }

      .links-text-title,
      .links-text-subtitle{
        color:#FFF;
        margin-bottom:5px;
      }

      .links-text-subtitle{
        font-weight:bold;
        margin:0;
      }
    }
  }
}
