.download-row {
  &:nth-child(odd){
    .download-field{
      background-color: #f2f2f2;
    }
  }
  &:nth-child(even){
    .download-field{
      background-color: #f9f9f9;
    }
  }
  a {
    font-size: 14px;

    &:hover {
      text-decoration: none;
    }

    .download-icon {
      font-size: 35px;
    }
  }
}

