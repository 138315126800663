.news-list{
  .news-list-block{
    display: block;
    position: relative;
    z-index: 3;
    margin: 0 0 30px;
    color:#000;
    text-decoration: none;

    .news-list-image {
      margin-bottom:20px;
    }

    .content-box-holder {
      display: block;
      transition:color .2s;

      h3 {
        margin:0 0 15px;
        font-size: 1.4em;
      }

      .keywords{
        margin-bottom:15px;
        color:#00794c;
      }

      .news-ticker-date{
        margin-top:20px;
        color:#b6bcb6;
      }


      .news-ticker-date,
      .keywords{
        text-transform:uppercase;
        @include font-folio;
      }
    }

    &:hover{
      color:#00794c;
    }
  }
}

.title-link{display:none}

.col-sidebar{
  .news-list{
    border-bottom: 1px solid #00794c;

    .title-link{display:block}

    .container{
      padding:0;
    }

    .col-md-6{
      max-width:100%;
      width:100%;
      flex:none;
    }

    .content-box-title{
      background-color: #00794c;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 10px;

      a,
      h3 {
        @include font-folio;
        text-transform: uppercase;
        display: block;
        color: #FFF;
        margin: 0;
        padding: 0;
      }

      h3{
        font-size: 1.6em;
      }

      a{
        text-decoration: underline;
        text-align: right;
      }

      &:before, &:after{
        display:none;
      }
    }
    .content-box-holder {
      display: flex;
      flex-direction: column-reverse;

      a.news-list-block{
        display: block;
        padding: 10px;
        background: #f1f1f1;
        border-bottom: 1px solid #e6e6e6;
        margin: 0;
        &:hover{
          text-decoration: none;
          background: #e6e6e6;
          color:inherit;
        }
      }

      .news-list-image{
        display:none;
      }

      .news-overview-text{
        display:none;
      }

      .news-ticker-date {
        margin:0;

        .date{
          font-family: 'Roboto',Helvetica,Arial,sans-serif;
          color: #00794c;
          font-size: 14px;
        }
      }

      h3 {
        margin:0;
        font-size: 18px;
      }
    }
  }
}

.news-box-sidebar {
  margin: 10px 0;

  .content-box-title{
    background-color: #00794c;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    a,
    h3 {
      @include font-folio;
      text-transform: uppercase;
      display: block;
      color: #FFF;
      margin: 0;
      padding: 0;
    }

    h3{
      font-size: 1.6em;
    }

    a{
      text-decoration: underline;
      text-align: right;
    }

    &:before, &:after{
      display:none;
    }
  }

  .event-holder{
    display:block;
    padding: 10px;
    background:#f1f1f1;
    border-bottom: 1px solid #e6e6e6;

    h4{
      color:#333;
      margin-bottom:0;
      font-size: 1.2rem;
    }
  }

  a.event-holder:hover{
    text-decoration:none;
    color: #00794c;
    background:#e6e6e6;
  }
}
