.twitter-block{
  background:#FFF;

  .content-box-holder{
    .content-box-content{
      border:none;
    }
  }
  .cta-block{
    position:relative;
    background-size:cover;
    background-position:center center;

    .btn-cta{
      font-size: 1.2em;
      padding-left: 60px;
      padding-right: 60px;
      text-transform: uppercase;
    }

    &:after {
      content:'';
      position: absolute;
      width: 100%;
      top:0;
      bottom: 0;
      left: 0;
      right: 0;
      background: -moz-linear-gradient(top, transparent 50%, rgba(0, 0, 0, .75) 100%);
      background: -webkit-linear-gradient(top, transparent 50%, rgba(0, 0, 0, .75) 100%);
      background: linear-gradient(to bottom, transparent 50%, rgba(0, 0, 0, .75) 100%);
      z-index: 5;
    }

    .cta-content{
      position:absolute;
      bottom:20px;
      left:20px;
      width:50%;
      @include font-folio;
      z-index: 6;

      .cta-title{
        color:#FFF;
        z-index: 6;
      }
    }
  }
}
