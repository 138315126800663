/*
This CSS resource incorporates links to font software which is the valuable copyrighted
property of Monotype and/or its suppliers. You may not attempt to copy, install,
redistribute, convert, modify or reverse engineer this font software. Please contact Monotype
with any questions regarding Web Fonts:  http://www.linotype.com
*/

//@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=e067c837-0312-4190-9572-1dcdfff6f499&fontids=1391174,692680,692692,692716");
@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=e067c837-0312-4190-9572-1dcdfff6f499&fontids=1391174");
//
@font-face {
    font-family:"Folio W01 Bold Condensed";
    src:url("../fonts/1391174/30b07aee-812c-4d78-87bd-fd95d6211f63.eot?#iefix");
    src:url("../fonts/1391174/30b07aee-812c-4d78-87bd-fd95d6211f63.eot?#iefix") format("eot"),url("../fonts/1391174/b69383c0-6752-4a7c-a1c9-649cfa51dc97.woff2") format("woff2"),url("../fonts/1391174/f6a1412c-7bc7-40b3-991f-9ef89dc16d6e.woff") format("woff"),url("../fonts/1391174/9c5f3d0b-32b1-40e2-a6bd-b96f63fbd965.ttf") format("truetype"),url("../fonts/1391174/88bad550-578e-4c5f-8a6f-c0a1fe5450ef.svg#88bad550-578e-4c5f-8a6f-c0a1fe5450ef") format("svg");
}

@font-face {
  font-family: 'reproduction';
  src: url('../fonts/reproduction/reproduction-medium.eot');
  src: url('../fonts/reproduction/reproduction-medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/reproduction/reproduction-medium.woff2') format('woff2'),
  url('../fonts/reproduction/reproduction-medium.woff') format('woff'),
  url('../fonts/reproduction/reproduction-medium.ttf') format('truetype'),
  url('../fonts/reproduction/reproduction-medium.svg#reproduction-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

//@font-face {
//    font-family:"HelveticaNeueW01";
//    src:url("../fonts/692680/ae1656aa-5f8f-4905-aed0-93e667bd6e4a.eot?#iefix");
//    src:url("../fonts/692680/ae1656aa-5f8f-4905-aed0-93e667bd6e4a.eot?#iefix") format("eot"),url("../fonts/692680/5a13f7d1-b615-418e-bc3a-525001b9a671.woff2") format("woff2"),url("../fonts/692680/530dee22-e3c1-4e9f-bf62-c31d510d9656.woff") format("woff"),url("../fonts/692680/688ab72b-4deb-4e15-a088-89166978d469.ttf") format("truetype"),url("../fonts/692680/7816f72f-f47e-4715-8cd7-960e3723846a.svg#7816f72f-f47e-4715-8cd7-960e3723846a") format("svg");
//    font-weight: 200;
//}
//@font-face {
//    font-family:"HelveticaNeueW01";
//    src:url("../fonts/692692/b7693a83-b861-4aa6-85e0-9ecf676bc4d6.eot?#iefix");
//    src:url("../fonts/692692/b7693a83-b861-4aa6-85e0-9ecf676bc4d6.eot?#iefix") format("eot"),url("../fonts/692692/b0868b4c-234e-47d3-bc59-41ab9de3c0db.woff2") format("woff2"),url("../fonts/692692/bcf54343-d033-41ee-bbd7-2b77df3fe7ba.woff") format("woff"),url("../fonts/692692/b0ffdcf0-26da-47fd-8485-20e4a40d4b7d.ttf") format("truetype"),url("../fonts/692692/da09f1f1-062a-45af-86e1-2bbdb3dd94f9.svg#da09f1f1-062a-45af-86e1-2bbdb3dd94f9") format("svg");
//}
//@font-face {
//    font-family:"HelveticaNeueW01";
//    src:url("../fonts/692716/c07fef9e-a934-42d7-92ad-69205f2b8a00.eot?#iefix");
//    src:url("../fonts/692716/c07fef9e-a934-42d7-92ad-69205f2b8a00.eot?#iefix") format("eot"),url("../fonts/692716/3b303641-706e-4221-94c4-4fb491f4f8ef.woff2") format("woff2"),url("../fonts/692716/14ff6081-326d-4dae-b778-d7afa66166fc.woff") format("woff"),url("../fonts/692716/8fda1e47-19be-46c7-8d83-8d4fb35572f0.ttf") format("truetype"),url("../fonts/692716/f751c8ae-1057-46d9-8d74-62592e002568.svg#f751c8ae-1057-46d9-8d74-62592e002568") format("svg");
//    font-weight: bold;
//}
//@font-face {
//    font-family:"HelveticaNeueW01";
//    src:url("../fonts/692716/c07fef9e-a934-42d7-92ad-69205f2b8a00.eot?#iefix");
//    src:url("../fonts/692716/c07fef9e-a934-42d7-92ad-69205f2b8a00.eot?#iefix") format("eot"),url("../fonts/692716/3b303641-706e-4221-94c4-4fb491f4f8ef.woff2") format("woff2"),url("../fonts/692716/14ff6081-326d-4dae-b778-d7afa66166fc.woff") format("woff"),url("../fonts/692716/8fda1e47-19be-46c7-8d83-8d4fb35572f0.ttf") format("truetype"),url("../fonts/692716/f751c8ae-1057-46d9-8d74-62592e002568.svg#f751c8ae-1057-46d9-8d74-62592e002568") format("svg");
//    font-weight: 500;
//}
/* source-sans-pro-300 - latin-ext_latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/source-sans/source-sans-pro-v21-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/source-sans/source-sans-pro-v21-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/source-sans/source-sans-pro-v21-latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/source-sans/source-sans-pro-v21-latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */
  url('../fonts/source-sans/source-sans-pro-v21-latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/source-sans/source-sans-pro-v21-latin-ext_latin-300.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

/* source-sans-pro-regular - latin-ext_latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/source-sans/source-sans-pro-v21-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/source-sans/source-sans-pro-v21-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/source-sans/source-sans-pro-v21-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/source-sans/source-sans-pro-v21-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/source-sans/source-sans-pro-v21-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/source-sans/source-sans-pro-v21-latin-ext_latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

/* source-sans-pro-italic - latin-ext_latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/source-sans/source-sans-pro-v21-latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/source-sans/source-sans-pro-v21-latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/source-sans/source-sans-pro-v21-latin-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/source-sans/source-sans-pro-v21-latin-ext_latin-italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/source-sans/source-sans-pro-v21-latin-ext_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/source-sans/source-sans-pro-v21-latin-ext_latin-italic.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

/* source-sans-pro-600 - latin-ext_latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/source-sans/source-sans-pro-v21-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/source-sans/source-sans-pro-v21-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/source-sans/source-sans-pro-v21-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/source-sans/source-sans-pro-v21-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
  url('../fonts/source-sans/source-sans-pro-v21-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/source-sans/source-sans-pro-v21-latin-ext_latin-600.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

/* permanent-marker-regular - latin */
@font-face {
  font-family: 'Permanent Marker';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/permanent-marker/permanent-marker-v16-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/permanent-marker/permanent-marker-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/permanent-marker/permanent-marker-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/permanent-marker/permanent-marker-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/permanent-marker/permanent-marker-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/permanent-marker/permanent-marker-v16-latin-regular.svg#PermanentMarker') format('svg'); /* Legacy iOS */
}

/* roboto-300 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/roboto/roboto-v30-latin_latin-ext-300.eot'); /* IE9 Compat Modes */
  src: url('../fonts/roboto/roboto-v30-latin_latin-ext-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto/roboto-v30-latin_latin-ext-300.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  url('../fonts/roboto/roboto-v30-latin_latin-ext-300.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
  url('../fonts/roboto/roboto-v30-latin_latin-ext-300.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
  url('../fonts/roboto/roboto-v30-latin_latin-ext-300.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-300italic - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/roboto/roboto-v30-latin_latin-ext-300italic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/roboto/roboto-v30-latin_latin-ext-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto/roboto-v30-latin_latin-ext-300italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  url('../fonts/roboto/roboto-v30-latin_latin-ext-300italic.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
  url('../fonts/roboto/roboto-v30-latin_latin-ext-300italic.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
  url('../fonts/roboto/roboto-v30-latin_latin-ext-300italic.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-regular - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto/roboto-v30-latin_latin-ext-regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/roboto/roboto-v30-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto/roboto-v30-latin_latin-ext-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  url('../fonts/roboto/roboto-v30-latin_latin-ext-regular.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
  url('../fonts/roboto/roboto-v30-latin_latin-ext-regular.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
  url('../fonts/roboto/roboto-v30-latin_latin-ext-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-italic - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/roboto/roboto-v30-latin_latin-ext-italic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/roboto/roboto-v30-latin_latin-ext-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto/roboto-v30-latin_latin-ext-italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  url('../fonts/roboto/roboto-v30-latin_latin-ext-italic.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
  url('../fonts/roboto/roboto-v30-latin_latin-ext-italic.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
  url('../fonts/roboto/roboto-v30-latin_latin-ext-italic.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-500 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/roboto/roboto-v30-latin_latin-ext-500.eot'); /* IE9 Compat Modes */
  src: url('../fonts/roboto/roboto-v30-latin_latin-ext-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto/roboto-v30-latin_latin-ext-500.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  url('../fonts/roboto/roboto-v30-latin_latin-ext-500.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
  url('../fonts/roboto/roboto-v30-latin_latin-ext-500.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
  url('../fonts/roboto/roboto-v30-latin_latin-ext-500.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-500italic - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/roboto/roboto-v30-latin_latin-ext-500italic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/roboto/roboto-v30-latin_latin-ext-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto/roboto-v30-latin_latin-ext-500italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  url('../fonts/roboto/roboto-v30-latin_latin-ext-500italic.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
  url('../fonts/roboto/roboto-v30-latin_latin-ext-500italic.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
  url('../fonts/roboto/roboto-v30-latin_latin-ext-500italic.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-700 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/roboto/roboto-v30-latin_latin-ext-700.eot'); /* IE9 Compat Modes */
  src: url('../fonts/roboto/roboto-v30-latin_latin-ext-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto/roboto-v30-latin_latin-ext-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  url('../fonts/roboto/roboto-v30-latin_latin-ext-700.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
  url('../fonts/roboto/roboto-v30-latin_latin-ext-700.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
  url('../fonts/roboto/roboto-v30-latin_latin-ext-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-700italic - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/roboto/roboto-v30-latin_latin-ext-700italic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/roboto/roboto-v30-latin_latin-ext-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto/roboto-v30-latin_latin-ext-700italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  url('../fonts/roboto/roboto-v30-latin_latin-ext-700italic.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
  url('../fonts/roboto/roboto-v30-latin_latin-ext-700italic.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
  url('../fonts/roboto/roboto-v30-latin_latin-ext-700italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
