.promo-box {
  display: block;
  position: relative;
  padding-bottom: 100%;
  margin-bottom: $grid-gutter-width;
  height: 0;
  z-index: 3;
  overflow: hidden;
  color: #FFF;
  background-color: #00794c;
  text-decoration: none;

  .background-image {
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
    transition: transform .2s;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 30%, rgba(0, 0, 0, 0.8) 100%);
      background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 30%, rgba(0, 0, 0, 0.8) 100%);
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 30%, rgba(0, 0, 0, 0.8) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#cc000000', GradientType=0);
      transition: background .2s;
      z-index: 5;
    }
  }

  &:hover {
    .background-image {
      transform: scale(1.1);
    }
  }

  .content-box-holder {
    display: block;
    position: absolute;
    bottom: 20px;
    right: 20px;
    left: 20px;
    z-index: 10;


    h2 {
      font-size: 2.8em;
      @include font-folio;
    }

    h2, p {
      color: #FFF;
      margin-bottom: 0;
      font-weight: bold;
    }
  }
}
