.header-calendar {
  background: #f9f9f9;
  height: 406px;
  padding: 15px 10px 10px;

  h2 {
    font-size: 1.8em;
  }

  .fc-view-container {
    background: #fff;
  }

  .fc-day-grid-event {
    padding: 3px 5px;
    border-radius: 0;
  }

  .fc-time {
    display: block;
  }

  .fc-title {
    display: block;
    margin-top: 15px;

    &:before {
      font-family: 'FontAwesome';
      content: "\f185";
      position: absolute;
      left: 0;
      top: 0;
      font-weight: bold;
    }
  }

  .fc-time + .fc-title {
    margin-top: 0;

    &:before {
      display: none;
    }
  }

  td.fc-widget-header {
    background: $brand-primary;
    color: #fff;
  }

  .fc-today {
    background: lighten(#B2D6C8, 15%);
  }

  .fc-state-default.fc-corner-right,
  .fc-state-default.fc-corner-left {
    border-radius: 0;
  }

  .event-legend {
    margin: 1px 0 0;
    padding: 0;
    list-style: none;

    li {
      float: left;
      padding-right: 15px;
    }

    .event-legend-color {
      display: inline-block;
      height: 10px;
      width: 15px;
    }
  }

  @include media-breakpoint-down(sm) {
    height: auto;
  }
}

.fc-event, .fc-event:hover, .ui-widget .fc-event {
  color: #fff !important;
}

.calendar-table-color {
  width: 14px;
  height: 18px;
}
