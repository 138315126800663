
.instagram-holder {
  border: 1px solid #e9eaed;
  margin-bottom: 10px;
  min-height: 500px;

  .instagram-header {
    font-size: 1.5em;
    font-weight: bold;
    padding: 2px 10px;
    border-bottom: 1px solid #e9eaed;
  }

  .instagram-content {
    height: 563px;
    overflow-x: auto;
  }

  .instagram-title {
    font-size: 1.2em;
    font-weight: bold;
    color: $brand-primary;
  }

  .instagram-time {
    text-align: right;
    color: #ccc;
    line-height: 24px;
  }

  .instagram-text {
    margin-bottom: 5px;
  }

  .instagram-image {
    max-width: 300px;
    margin: 0 auto;
    position: relative;
  }

  .instagram-video {
    @include opacity(0.8);
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    font-size: 7em;
    margin: -70px 0 0 -40px;
  }

  .instagram-box {
    padding: 20px 10px;
    border-bottom: 1px solid #e9eaed;
  }
}
