.survey-box {
  max-width: 400px;
  margin: 10px auto;

  .survey-box-title {
    background: $brand-primary;

    h2 {
      margin: 0;
      color: #fff;
      font-size: 1.2em;
      font-weight: bold;
      padding: 5px 10px 5px 10px;
      text-transform: uppercase;
    }
  }

  .survey-box-content {
    padding: 10px 10px 0;
    border: 1px solid $brand-primary;

    .survey-question {
      font-size: 1.3em;
      font-weight: bold;
    }
  }

  .survey-result {
    border-top: 1px solid #eee;
    background: #f6f6f6;
    margin: 0 -10px;
    padding: 10px;

    .progress {
      background: #fff;
    }
  }
}
