.accordion-item {
  border: 1px solid #ddd;
  .accordion-header {
    width: 100%;
    border-bottom: 1px solid #ddd;
    background-color: #f5f5f5;
    color: #333;
    font-size: 16px;
    font-weight: 500;
  }
  .accordion-icon {
    transition: transform .2s;
    color: grey;
    font-size: 20px;
  }

  .collapsed {
    border-bottom: none;
    .accordion-icon {
      transform: rotate(180deg);
    }
  }
}
