.btn {
  background: $btn-default-bg;
  border: solid $btn-default-border;
  border-width: 1px;

  &:hover,
  &:focus,
  &.focus {
    background: darken($btn-default-bg, 10%);
  }
}

@mixin button-variant($color, $background, $border) {
  background-color: $background;
  border-color: $border;

  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-color: darken($background, 10%);
    border-color: darken($border, 12%);
  }

  .badge {
    color: $background;
    background-color: $color;
  }
}


.btn-default {
  @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
}

.btn-primary {
  @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
}

.btn-success {
  @include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
}

.btn-info {
  @include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
}

.btn-warning {
  @include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
}

.btn-danger {
  @include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
}

.btn-link {
  border: none;
  background: transparent;

  &,
  &:hover,
  &:focus,
  &:active,
  &.active,
  &[disabled],
  fieldset[disabled] & {
    border-color: transparent;
  }

  &:hover,
  &:focus {
    background-color: transparent;
  }
}

.btn-app {
  min-width: 90px;
  padding: 12px;

  span,
  i {
    font-size: 1.8em;
    line-height: 1em;
    display: block;
    margin: 0 0 2px;
    padding: 0;
  }

  &.btn-xs {
    min-width: 65px;
    padding: 5px;

    span,
    i {
      font-size: 1.5em;
    }
  }

  &.btn-sm {
    min-width: 70px;
    padding: 8px;
  }

  &.btn-lg {
    min-width: 100px;
    padding: 15px;

    span,
    i {
      font-size: 2em;
    }
  }

  &.btn-link {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active,
    &[disabled],
    fieldset[disabled] & {
      text-decoration: none;
    }
  }
}
