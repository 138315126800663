.content-box {
  background: #fff;
  position: relative;
  line-height:1.42857;

  &.content-box-dark {
    .content-box-title {
      background: darken($brand-primary, 5%);
    }
  }

  .content-inner-box {
    background: rgba(0, 0, 0, 0.2);
    margin: 10px 0;

    .content-inner-box-header {
      background: rgba(0, 0, 0, 0.2);
      text-transform: uppercase;
      font-weight: bold;
      padding: 3px 10px;
    }

    .content-inner-box-content {
      padding: 3px 10px;
    }
  }

  .overview-player {
    position: relative;
    padding: 0 5px;
    margin: 10px 0;
    display: block;
    color: #fff;

    &:hover, &:focus {
      @include opacity(0.8);
      color: #fff;
    }
  }
  .overview-player-name {
    position: absolute;
    left: 5px;
    bottom: 0;
    right: 5px;
    background: rgba(0, 0, 0, 0.5);
    font-weight: bold;
    padding: 3px 10px;
  }


  .content-box-picture {
    border: 1px solid $brand-primary;
    border-top: 0;
    position: relative;
    min-height: 60px;

    .play-btn {
      position: absolute;
      top: 20%;
      left: 50%;
      margin-left: -30px;
    }
  }

  a.content-box-picture {
    display: block;
    border-bottom:0px;

    &:hover {
      @include opacity(0.9);
    }
  }

  .content-box-title {
    background: $brand-primary;
    display: flex;
    justify-content: space-between;

    i{
      margin: 6px 10px;
    }

    h3, h2, h1 {
      line-height:normal;
      margin: 0;
      font-size: 1.2em;
      font-weight: bold;
      padding: 5px 10px 5px 10px;
      color: #fff;
      text-transform: uppercase;
    }

    a {
      font-weight: normal;
      color: #fff;
      text-decoration: underline;
      text-transform: none;
      line-height: 1.3em;
      padding: 5px 10px 5px 10px;

      &:hover {
        color: #eee;
      }
    }
  }
  .content-box-holder {
    position: relative;

    a {
      color: #fff;
      text-decoration: underline;

      &:hover {
        color: #eee;
      }
    }
  }
  a.content-box-holder {
    display: block;

    &:hover {
      @include opacity(0.9);
    }
  }
  .content-box-content {
    padding: 0 10px;
    border: 1px solid $brand-primary;

    a {
      cursor: pointer;
      color: $brand-primary;
      text-decoration: none;

      &:hover {
        color: darken($brand-primary, 10%);
        text-decoration: underline;
      }
    }
  }
  .content-box-content-over {
    padding: 5px 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 15px;
    font-size: 0.9em;

    p {
      margin: 0;
    }
  }
  .content-box-content-over-2 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: #fff;
  }

  .event-holder {
    background: darken($brand-primary, 4%);
    color: #fff;
    padding: 5px 10px;
    margin: 3px -7px;
    display: block;

    h4 {
      margin: 0;
      font-size: 1em;
      text-transform: uppercase;
    }

    div {
      font-size: 0.9em;
    }
  }

  a.event-holder {
    color: #fff;

    &:hover {
      color: #fff;
      text-decoration: none;
      background: darken($brand-primary, 6%);
    }
  }

  .content-box-table {
    margin: 0;
    color: #fff;
    font-weight: bold;
    text-align: center;

    &>thead>tr>th,
    &>tbody>tr>td {
      border-color: #fff;
    }

    a {
      color: #fff;
    }

    th {
      font-size: 0.9em;
    }

    th,
    td {
      padding: 4px 5px;
    }

    th {
      text-align: center;
    }
    th.col1 {
      text-align: left;
    }
    td.col1 {
      font-weight: bold;
      text-align: left;
      text-transform: uppercase;
    }

    .highlight {
      background: rgba(255, 255, 255, 0.15);
    }
  }

  .table-panel {
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
    font-weight: normal;
    margin: 0;
  }

  .table-panel-header {
    margin: 10px 36px 0 15px;
    font-size: 1.2em;

    th {
      padding: 5px 0;
    }
  }

  .panel-group-table {
    margin: 0;
  }

  .panel-table {
    background: transparent;
    border: 1px solid #fff;

    &.panel-table+.panel-table {
      margin-top: -1px;
    }

    & > .panel-heading {
      background: transparent;
      font-size: 1em;
      padding-top: 8px;
      padding-bottom: 8px;

      h4 {
        font-size: 1em;
      }

      a {
        text-decoration: none;

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }

    .panel-heading.accordion-toggle:hover {
      background: rgba(255,255,255,0.1);

      a {
        text-decoration: none;
      }
    }

    .panel-heading.accordion-toggle:after {
      color: #fff;
      font-size: 15px;
      top: 3px;
    }

    .panel-heading+.panel-collapse>.list-group,
    .panel-heading+.panel-collapse>.panel-body {
      border-top: 1px solid #fff;
      background: rgba(255,255,255,0.05);
    }
  }

  .content-box-content-radial-bg {
    $light: darken(#007946, 2%);
    $dark: #004324;

    padding: 1px 10px;
    color: #fff;
    background: $light; /* Old browsers */
    background: -moz-radial-gradient(center, ellipse cover, $light 45%, $dark 100%); /* FF3.6+ */
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(45%,$light), color-stop(100%,$dark)); /* Chrome,Safari4+ */
    background: -webkit-radial-gradient(center, ellipse cover, $light 45%,$dark 100%); /* Chrome10+,Safari5.1+ */
    background: -o-radial-gradient(center, ellipse cover, $light 45%,$dark 100%); /* Opera 12+ */
    background: -ms-radial-gradient(center, ellipse cover, $light 45%,$dark 100%); /* IE10+ */
    background: radial-gradient(ellipse at center, $light 45%,$dark 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007946', endColorstr='#004324',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  }

  .content-box-content-radial-bg-grey {
    $light: #fff;
    $dark: #ddd;

    border: 1px solid $brand-primary;
    padding: 1px 10px;
    background: $light; /* Old browsers */
    background: -moz-radial-gradient(center, ellipse cover, $light 45%, $dark 100%); /* FF3.6+ */
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(45%,$light), color-stop(100%,$dark)); /* Chrome,Safari4+ */
    background: -webkit-radial-gradient(center, ellipse cover, $light 45%,$dark 100%); /* Chrome10+,Safari5.1+ */
    background: -o-radial-gradient(center, ellipse cover, $light 45%,$dark 100%); /* Opera 12+ */
    background: -ms-radial-gradient(center, ellipse cover, $light 45%,$dark 100%); /* IE10+ */
    background: radial-gradient(ellipse at center, $light 45%,$dark 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007946', endColorstr='#004324',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  }
}

#home{
  .content-box {
    margin: 0;
    padding: 0 50px 30px;

    @include media-breakpoint-down(sm){
      padding: 0 20px 10px;
    }

    .content-box-title {
      background: #FFFFFF;
      padding: 30px 0 15px;

      display: flex;
      justify-content: space-between;
      align-items: center;
      &:before, &:after{display:none;}

      h3 {
        padding: 3px 0 0;
        color: #007a4b;
        text-transform: uppercase;
        font-size: 3em;
        @include font-folio;
      }

      a {
        padding: 6px 60px;
        font-size: 1.2em;
        display: block;
        background: #007a4b;
        color: #FFF;
        text-decoration: none;
        border-radius: 5px;
        font-weight: bold;
        text-transform: uppercase;
        @include font-folio;
        transition: background .3s;

        &:hover {
          background: darken(#007a4b, 5%);
        }
      }
    }

    .content-box-footer{
      margin:10px 0 0;
      padding: 10px 0;
      @include media-breakpoint-down(md){
        a {
          font-size: 1.2em;
          display: block;
          text-align:center;
          padding: 6px 60px;
          background: #007a4b;
          color: #FFF;
          text-decoration: none;
          border-radius: 5px;
          font-weight: bold;
          text-transform: uppercase;
          @include font-folio;
          transition: background .3s;

          &:hover {
            background: darken(#007a4b, 5%);
          }
        }
      }
    }

    &.bg-grey {
      .content-box-title {
        background: #f1f1f1;
      }
      .content-box-holder {
        &, .content-box-content-radial-bg {
          background: #f1f1f1;
        }
      }
    }

    .content-box-holder {
      &, .content-box-content-radial-bg {
        background: #FFFFFF;
        color: #007a4b;
      }
    }
  }
}
