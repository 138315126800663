body.gateway {
  background: #16482f;
  height: 100%;
  min-height: 100vh;

  .gateway-content{
    min-height: 100vh;

    .gateway-bg {
      background: url('../img/landing-page-bg.jpg') center center no-repeat fixed;
      background-size: cover;
      width: 100%;
      min-height: 100vh;
    }

    .gateway-img {
      position: relative;
      display: block;
      cursor: pointer;

      .gateway-link {
        position: absolute;
        bottom: 9%;
        left: 10px;
        right: 10px;
        text-align: center;
        text-decoration: underline;
        font-size: 1.5em;
        text-transform: uppercase;
        color: #fff;

        div {
          display: inline-block;
          padding: 3px 15px;
          @include border-radius(3px);
          background: rgba(0,0,0,0.4);
        }

        @include media-breakpoint-down(sm) {
          font-size: 1.2em;
          bottom: 5%;
        }

        @include media-breakpoint-down(xs) {
          font-size: 1em;
          bottom: 3%;
        }
      }

      video {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    .promo-landing {
      margin-top: 15px;
      position: relative;
    }
    .gateway-promo-link {
      position: absolute;
      bottom: 20px;
      left: 20px;
      padding: 3px 15px 1px;
      border-radius: 3px;
      background: rgba(0,0,0,.6);
      font-size: 1.9em;
      text-transform: uppercase;
      color: #fff;
      text-align: center;
      text-decoration: none;
      font-family: 'Folio W01 Bold Condensed',Helvetica,Arial,sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      @media (max-width: 600px) {
        position: static;
        display: block;
        margin: 10px 0 0;
      }

      &.right {
        left: auto;
        right: 20px;
      }

      &:active, &:focus, &:hover {
        color: #fff;
        background: rgba(0,0,0,.8);
        text-decoration: none;
        outline: 0;
      }
    }

    .promo-overlay {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;

      .play-btn {
        position: absolute;
        left: 50%;
        top: 47%;
        margin: -25px 0 0 -25px;
        width: 50px;
        height: 50px;
        border: 4px solid #fff;
        border-radius: 50%;
        text-align: center;
        line-height: 41px;
        padding-left: 5px;
        font-size: 25px;
        color: #fff;
        background: rgba(0,0,0,0.3);
      }
    }


    .language-select {
      margin-left: 10px;
      list-style: none;
      display: inline-block;
      padding: 0;
      font-size: 0.9em;
      font-weight: bold;

      li {
        display: inline-block;
      }

      a {
        color: #fff;
        text-transform: uppercase;
      }
      img {
        width: 24px;
        height: auto;
      }

      @include media-breakpoint-down(sm){
        margin-top: 0;
        display: block;
      }
    }

    .header-wien-energie {
      width: 115px;
      height: auto;
    }

    .content-box-title h3 {
      float: left;
      margin: 0;
      font-size: 1.2rem;
      font-weight: 700;
      padding: 5px 10px 5px 10px;
      color: #fff;
      text-transform: uppercase;
      font-family: 'Roboto',Helvetica,Arial,sans-serif;
    }
  }
}
