.col-sidebar{
  .table-box{

    .content-box-title{
      display:flex;
      justify-content: space-between;
      align-items: center;
      background-color: #00794c;
      padding: 20px 10px;

      a,
      h3 {
        @include font-folio;
        text-transform: uppercase;
        display: block;
        color: #FFF;
        margin: 0;
        padding: 0;
      }

      h3{
        font-size: 1.6em;
      }

      a{
        text-decoration: underline;
        text-align: right;
      }

      &:before, &:after{
        display:none;
      }
    }


    .content-box-content{
      display:flex;
      flex-direction:column;
      justify-content: flex-start;

      .event-holder{
        display:block;
        padding: 10px;
        background:#f1f1f1;
        border-bottom: 1px solid #e6e6e6;

        h4{color:#333;margin-bottom:0;}
      }

      a.event-holder:hover{
        text-decoration:none;
        color: #00794c;
        background:#e6e6e6;
      }
    }

    .table{
      margin-bottom:0;
      max-width:100%;
      thead{
        tr{
          color:#FFF;
          background: #00794c;
          th{
            border-top:none;
          }
        }
      }
      tbody{
        tr{
          background: #f1f1f1;
          td{
            font-weight:bold;
            .col1{
              text-transform:uppercase;
            }
          }
          &.highlight{
            background: #e6e6e6;
            td{
              font-weight:bold;
              color: #00794c;
            }
          }
        }
      }
    }
  }

  .content-box .content-box-table.standings-table{
    color:#333;
  }
}
